/* eslint-disable no-console,no-undef,camelcase */
import React, { Component, Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { DropdownItem, DropdownButton } from 'react-bootstrap';
import _, { isNull, isUndefined } from 'lodash';
import { StyledModal, StyledBody, MainContent, PeerButton } from '../RecognitionList/styles';

import {
  HeaderContainer, UserInformation, DownAngle, FeedInformation, FeedFeatures, LikeCounts, CommentCounts,
  ImageWrapper, WrapImage, InputFieldContainer, RecognitionSection, AddBuddyContainer, RemoveButtonContainer, TimeOfPost, Text2
  , FieldContainer, Option, RecognitionText, Description, PendingButton, CommentContent, LikeButtonew, FeedTabNew
} from '../SocialFeedsV2/styles';
import Image from '../Image';
// import ImageThumbnail from '../ImageThumbnail';
import SocialFeedTextarea from '../SocialFeedsV2/SocialFeedTextarea';
const ViewCommentModal = lazy(() => import('../ViewCommentModal'));
import { ImageUrl } from '../../utils/constants';
import { convertDateInTimezone, getTimeToShow, applyHighlights, convertUnicode, createSafeHTML } from '../../utils/methods';
import { getUserPosts, addCommentOnPost, disableButton, likeUnlikePost, getUserName, getUserId, sendFriendRequest, unFriendUser, getPostLikeDetails, addBuddy } from '../../redux/actions';
// import Loading from "../Loading";
const AddBuddyPopUp = lazy(() => import('../SocialFeedsV2/AddBuddyPopUp'));
const RemoveBuddyPopUp =  lazy(() => import('../SocialFeedsV2/RemoveBuddyPopUp'));
import { withTranslation } from 'react-i18next';
import SocialFeedsEmojiPicker from '../SocialFeedsEmojiPicker';
import { managePostLikedUsers } from '../../redux/actions/socialActions';
import Waiting from '../Waiting';


class SingleFeed extends Component {
  constructor(props) {
    super();
    this.state = {
      showTextArea: false,
      commentText: '',
      clicked: false,
      isPostLiked: props.feed && props.feed.own_like_post === 1,
      notBase64: false,
      time: [],
      mentionedUsers: [],
      users: [],
      showGivenShoutout: false,
      shoutoutText: null,
      shoutoutImage: null,
      userIdArray: [],
      taggedUser: '',
      shoutoutType: 0,
      exemplifyID: null,
      showAddBuddy: false,
      showRemoveBuddyButton: false,
      showRemoveBuddyPopUp: false,
      ShowEmojiContainer: false,
      displayEmojiPicker: false,
      likeUsers: [],
      showRecognitionModal: false,
      postId: '',
      currentButton: '',
      feedLikeStatus: null,
      showViewCommentModal: props.showViewCommentModal ? props.showViewCommentModal : false,
      hideCommentText: props.hideCommentText === false ? false : true,
      postLikeDetail: [],
      userIdArrayV2: [],
      usersV2: [],
      position: {start: 0, end: 0}
    }
    this.myref = React.createRef();
  }

  handleClick = (event) => {
    if (!isNull(this.myref) && !isUndefined(this.myref) && !(this.myref && !isNull(this.myref.current) && !isUndefined(this.myref.current) && this.myref.current.contains(event.target))) {
      this.setState({ ShowEmojiContainer: false });
    }
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick);
    const { feed } = this.props;
    if (feed && feed.own_like_post === 1) {
      this.setState({
        feedLikeStatus: true
      })
    } else {
      this.setState({
        feedLikeStatus: false
      })
    }

    this.checkBase64(this.props.profileImage);
    if (!this.props.userName) this.props.fetchUserName();
    if (!this.props.userId) this.props.fetchUserId();
    this.setState({
      time: getTimeToShow(feed, 'feeds')
    });

    this.interval = setInterval(() => this.setState({
      time: getTimeToShow(feed, 'feeds')
    }), 60 * 1000);

    if (feed.friend === 1) {
      this.setState({
        currentButton: 'Remove Buddy'
      })
    } else if (feed.friend === 0) {
      this.setState({
        currentButton: 'Add Buddy'
      })
    } else if (feed.friend === 2) {
      this.setState({
        currentButton: 'Pending'
      })
    } else {
      this.setState({
        currentButton: ''
      })
    }
  }

  componentWillUnmount() {
    setInterval(() => clearInterval(this.interval), 65 * 1000);
    document.removeEventListener("mousedown", this.handleClick);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.successLikeOrUnlike !== nextProps.successLikeOrUnlike) {
      this.setState({
        clicked: false
      });
    }
    if (this.props.profileImage != nextProps.profileImage) {
      this.checkBase64(nextProps.profileImage);
    }

    if (this.props.feed !== nextProps.feed) {
      this.setState({
        time: getTimeToShow(nextProps.feed, 'feeds')
      });
    }
  }

  // updateFeedsLike = (storedFeeds) => {
  //   managePostLikedUsers(storedFeeds);
  // }

  componentDidUpdate(prevProps) {
    if (this.props.postLikeDetails !== prevProps.postLikeDetails) {
      let feeds = this.props.storedFeeds;
      let feedIndex = feeds.findIndex((data) => data.id == this.state.postId);
      if (feedIndex !== -1) {
        feeds[feedIndex].post_like_users = this.props.postLikeDetails;
        this.props.updateFeedsLike(feeds);
        this.setState({ postLikeDetail: this.props.postLikeDetails, postId: '' });
      }
    }
  }

  checkBase64(profileImage) {
    if (profileImage) {
      let arr = profileImage.split('/');
      if (arr[0] !== 'profile-Image') {
        this.setState({
          notBase64: false
        })
      }
      else {
        this.setState({
          notBase64: true
        })
      }
    }
  }

  showAddBuddyPopUp2 = () => {
    this.setState({ showAddBuddy: false });
  }

  showAddBuddyPopUp = (ID) => {
    const { addBuddy, feed } = this.props;
    this.setState({ showAddBuddy: true });
    addBuddy(ID);
    if (feed.friend === 1) {
      this.setState({
        currentButton: 'Remove Buddy'
      })
    } else if (feed.friend === 0) {
      this.setState({
        currentButton: 'Add Buddy'
      })
    } else if (feed.friend === 2) {
      this.setState({
        currentButton: 'Pending'
      })
    } else {
      this.setState({
        currentButton: ''
      })
    }
  }

  showTextArea = () => {
    this.setState((prev) => ({
      showTextArea: !prev.showTextArea
    }));
  };

  setRemoveBuddy = () => {
    // const { unFriendUser } = this.props;
    this.setState((prev) => ({ showRemoveBuddyButton: !prev.showRemoveBuddyButton }));
    // unFriendUser(ID, true);
  }

  handleLikePopUp = () => {
    const { handleModal, handleFeed, feed } = this.props
    handleModal();
    handleFeed(feed.id);
  }

  likeUnlikePost = () => {
    const { likeOrUnlikePost, disableLikeButton, feed, updationList } = this.props;
    const likeData = {
      post_id: feed.id,
      flag: feed && feed.own_like_post === 1 ? 'unlike' : 'like',
    };
    this.setState((prev) => ({
      clicked: true,
      isPostLiked: !prev.isPostLiked,
      postId: '',
      feedLikeStatus: !prev.feedLikeStatus,
      postLikeDetail: []
    }), () => this.setState({ postId: feed.id }));
    disableLikeButton();
    likeOrUnlikePost(likeData, updationList || 'response');
  };

  addComment = () => {
    const { users, userIdArray, commentText, taggedUser, mentionedUsers, exemplifyID } = this.state;
    const { postComment, feed, updationList, getCommentsForPost } = this.props;
    let shoutout = {
      exemplifies: mentionedUsers.length > 0 ? mentionedUsers : userIdArray
    };
    let comment = this.toCodePoints(commentText), shoutout_user_name = '';
    if (!_.isEmpty(mentionedUsers) || !_.isEmpty(userIdArray)) {
      shoutout_user_name = `${applyHighlights(users, userIdArray, taggedUser)} - ${this.state.shoutoutText}`;
    }
    let exemplify_id = exemplifyID;
    if (shoutout.exemplifies.length >= 1 || commentText !== "") {
      postComment({ post_id: feed.id, comment, shoutout, shoutout_user_name, exemplify_id }, updationList);
    }

    this.setState({
      showTextAreaFor: null,
      commentText: '',
      showGivenShoutout: false,
      shoutoutImage: null,
      shoutoutText: null,
      taggedUser: '',
      userIdArray: [],
      users: [],
      mentionedUsers: [],
      displayEmojiPicker: false,
      shoutoutType: 0,
      userIdArrayV2: [],
      usersV2: []
    }, () => getCommentsForPost(feed.id))
  };

  onChangeValue = (e) => {
    let position = this.getPosition(e.target)
    let text =  e.target.value
    this.setState({
      commentText: text,
      position: position
    });
  };

  toCodePoints = function (string) {
    let chars = "";
    for (let i = 0; i < string.length; i++) {
      let c1 = string.charCodeAt(i);
      let b1 = string.charAt(i);
      if (c1 >= 0xD800 && c1 < 0xDC00 && i + 1 < string.length) {
        let c2 = string.charCodeAt(i + 1);
        if (c2 >= 0xDC00 && c2 < 0xE000) {
          chars = chars + ("&#" + ((+0x10000 + ((c1 - 0xD800) << 10) + (c2 - 0xDC00))) + ";</span>");
          i++;
          continue;
        }
      }
      else {
        chars = chars + b1;
      }
    }
    return chars;
  }

  getPosition(el) {
    let start = 0, end = 0, normalizedValue, range,
      textInputRange, len, endRange;

    if (typeof el.selectionStart == "number" && typeof el.selectionEnd == "number") {
      start = el.selectionStart;
      end = el.selectionEnd;
    } else {
      range = document.selection.createRange();

      if (range && range.parentElement() == el) {
        len = el.value.length;
        normalizedValue = el.value.replace(/\r\n/g, "\n");
        textInputRange = el.createTextRange();
        textInputRange.moveToBookmark(range.getBookmark());
        endRange = el.createTextRange();
        endRange.collapse(false);

        if (textInputRange.compareEndPoints("StartToEnd", endRange) > -1) {
          start = end = len;
        } else {
          start = -textInputRange.moveStart("character", -len);
          start += normalizedValue.slice(0, start).split("\n").length - 1;

          if (textInputRange.compareEndPoints("EndToEnd", endRange) > -1) {
            end = len;
          } else {
            end = -textInputRange.moveEnd("character", -len);
            end += normalizedValue.slice(0, end).split("\n").length - 1;
          }
        }
      }
    }

    return {
      start: start,
      end: end
    };
  }

  addInspirationV2 = (e) => {
    let newText = this.state.commentText.slice(0, this.state.position.start) + e.native + this.state.commentText.slice(this.state.position.end);
    this.setState({
      position: { start: this.state.position.start + e.native.length, end: this.state.position.end + e.native.length },
      commentText: newText,
    });
  };

  updateText = (text, userId, taggedUserName) => {
    const { userIdArray, users, taggedUser } = this.state;
    let userIdArr = [...userIdArray];
    let userNameArr = [...users];
    let taggedUsers = taggedUser;
    if (userIdArr.indexOf(userId) === -1) {
      userIdArr.push(userId);
      userNameArr.push(taggedUserName);
      if (!_.isEmpty(taggedUsers))
        taggedUsers = `${taggedUsers}, ${taggedUserName}`;
      else
        taggedUsers = taggedUserName;
    }
    this.setState({
      commentText: text,
      userIdArray: userIdArr,
      users: userNameArr,
      // taggedUser: taggedUsers,
      // showGivenShoutout: bool
    });
  };

  updateShoutoutArray = (mentionedUsers, text, image, exemplifies_id) => {
    this.setState({
      showGivenShoutout: true,
      shoutoutText: text,
      shoutoutImage: image,
      shoutoutType: exemplifies_id ? exemplifies_id : null,
      mentionedUsers: mentionedUsers,
      exemplifyID: exemplifies_id ? exemplifies_id : null
    });
  };

  onRemoveShoutout = (array) => {
    this.setState({
      [array]: [],
      showGivenShoutout: false,
      taggedUser: '',
      shoutoutImage: null,
      shoutoutText: null,
      userIdArray: [],
      users: [],
      usersV2: '',
      userIdArrayV2: []
    });
  };

  onRemoveUser = () => {
    this.setState({
      taggedUser: '',
      showGivenShoutout: false,
      userIdArray: [],
      shoutoutImage: null,
      shoutoutText: null,
      users: []
    });
  };

  updateTextV2 = (text, userId, taggedUserName, bool) => {
    const { userIdArrayV2, usersV2, taggedUser, userIdArray } = this.state;
    let userIdArr = [...userIdArrayV2];
    let userNameArrV2 = [...usersV2];
    let userIdAll = [...userIdArray]
    let taggedUsers = taggedUser;
    if (userIdArr.indexOf(userId) === -1) {
      userIdArr.push(userId);
      userIdAll.push(userId);
      userNameArrV2.push(taggedUserName);
      if (!_.isEmpty(taggedUsers))
        taggedUsers = `${taggedUsers}, ${taggedUserName}`;
      else
        taggedUsers = taggedUserName;
    }
    this.setState({
      userIdArrayV2: userIdArr,
      usersV2: userNameArrV2,
      taggedUser: taggedUsers,
      showGivenShoutout: bool,
      userIdArray: userIdAll
    });
  };


  togglePostButton = (value) => {
    const { shoutoutText, userIdArray } = this.state;
    if (userIdArray.length !== 0) {
      if (userIdArray.length !== 0 && !_.isNull(shoutoutText)) {
        return false;
      } else
        return true;
    } else if (value && value.trim() !== '') {
      return false;
    }
    return true;
  };

  displayEmojiContainer = () => {
    this.setState((prev) => ({
      ShowEmojiContainer: !prev.ShowEmojiContainer
    }));
  }

  showEmojiPicker = () => {
    this.setState((prev) => ({
      displayEmojiPicker: !prev.displayEmojiPicker
    }))
  }

  updatePosition = (position) =>{
    this.setState({
      position:{start: this.state.position.start+position, end: this.state.position.end+position }
    })
  }

  renderTextArea = (value) => {
    const { handlePhotoPostModal } = this.props;
    return (
      <FieldContainer isCommentOrReply socialPage borderTop={"1"} width>
        <CommentContent height={this.state.showGivenShoutout ? 1 : 0}>
          <ImageWrapper isCommentSection>
            {this.imgSrc(true)}
          </ImageWrapper>
          <InputFieldContainer comment padding={'15px'} height={'100%'} width isCommentOrReply>
            <SocialFeedTextarea
              userCompany={this.props.userCompany}
              addInspiration={this.onChangeValue}
              addInspirationV2={this.addInspirationV2}
              updateTextV2={this.updateTextV2}
              inspirationQuote={this.state.commentText}
              updateText={this.updateText}
              users={this.state.users}
              usersV2={this.state.usersV2}

              userIdArray={this.state.userIdArray}
              placeholder={this.props.t("Write a comment...")}
              showGivenShoutout={this.state.showGivenShoutout}
              updateShoutoutArray={this.updateShoutoutArray}
              emptyShoutoutArray={this.emptyShoutoutArray}
              onRemoveShoutout={this.onRemoveShoutout}
              isCommentOrReply={"isCommentOrReply"}
              shoutoutText={this.state.shoutoutText}
              shoutoutImage={this.state.shoutoutImage}
              taggedUser={this.state.taggedUser}
              onRemoveUser={this.onRemoveUser}
              enableTrigger={this.props.enableTrigger}
              ShowEmojiContainer={this.state.ShowEmojiContainer}
              myref={this.myref}
              className={'CustomTextAreaField'}

              shoutOutData={null}
              updatePosition={this.updatePosition}

            />

            <Option>
              <div onClick={() => handlePhotoPostModal(1, this.props.feed.id)}>
                <img style={{ margin: '0px' }} src="/public/images/camera_icon.svg" />
              </div>
              <div onClick={() => this.showEmojiPicker()}>
                <img  style={{ margin: '0px' }} src='/public/images/emoji_icon.svg' />
              </div>
              <div onClick={this.addComment} disabled={this.togglePostButton(value)}>
                <img style={{ margin: '0px', marginTop:"5px" }} src="/public/images/social/send.svg" />
              </div>
            </Option>
          </InputFieldContainer>
        </CommentContent>
        {this.state.displayEmojiPicker ? <SocialFeedsEmojiPicker comment selectEmoji={this.addInspirationV2} hidePicker={this.showEmojiPicker}></SocialFeedsEmojiPicker> : null}

        <RecognitionSection>
          <div>
            <div onClick={() => this.displayEmojiContainer()}>
              <img src="/public/images/social/peer_recognition.svg" />
            </div>
            <div>
              <img src="/public/images/CommentReplyImages/camera.png" />
              <input
                id="uploadPhoto"
                type="file"
                name="image"
                accept=".jpeg, .png, .jpg*"
                multiple={false}
                onChange={(e) => this.props.onChange(e)}
                onClick={(e) => e.target.files[0] && this.props.onChange(e)}
              />
            </div>
            <div onClick={() => this.showEmojiPicker()}>
              <img src="/public/images/CommentReplyImages/Emoji_2.png" />
            </div>
          </div>
          <button onClick={this.addComment} disabled={this.togglePostButton(value)}>
            <img src="/public/images/CommentReplyImages/addPost.png" />
          </button>
        </RecognitionSection>
      </FieldContainer>
    )
  }


  navigateUserProfile = (uid) => {
    const { history, userId, fetchUserPosts } = this.props;
    fetchUserPosts(uid, history);
    if (userId === uid) {
      history.push('/profile');
    } else {
      history.push(`/profile/${uid}`);
    }
  };

  imgSrc = (isOwn) => {
    const { userId, feed, profileImage } = this.props;
    const { notBase64 } = this.state;
    if (isOwn) {
      if (!notBase64) {
        return (<img src={`${ImageUrl}/${profileImage}`} />)
      }
      else {
        return (<Image image={profileImage} />)
      }
    } else if (userId == feed.uid) {
      if (!notBase64) {
        return (<img src={`${ImageUrl}/${profileImage}`} onClick={() => this.navigateUserProfile(feed.uid)} />)
      }
      else {
        return (<Image image={profileImage} onClick={() => this.navigateUserProfile(feed.uid)} />)
      }
    }
    else {
      return (<Image image={feed.profile_image} onClick={() => this.navigateUserProfile(feed.uid)} />)
    }
  };

  getTaggedUserId = (e) => {
    if (e.target.id) {
      this.navigateUserProfile(e.target.id);
    }
  };

  displayRemovePopUp = () => {
    const { socialCallBack, feed } = this.props;
    this.setState((prev) => ({ showRemoveBuddyPopUp: !prev.showRemoveBuddyPopUp }));
    if (feed.friend === 1) {
      this.setState({
        currentButton: 'Remove Buddy'
      })
    } else if (feed.friend === 0) {
      this.setState({
        currentButton: 'Add Buddy'
      })
    } else if (feed.friend === 2) {
      this.setState({
        currentButton: 'Pending'
      })
    } else {
      this.setState({
        currentButton: ''
      })
    }
    socialCallBack();
  }

  showRecognition = () => {
    this.setState((prev) => ({
      showRecognitionModal: !prev.showRecognitionModal
    }));
  }

  onShoutoutSelect = (text, id, image) => {
    const { userIdArray } = this.state;
    this.setState((prev) => ({
      showRecognitionModal: !prev.showRecognitionModal,
      shoutoutText: text,
      shoutoutImage: image,
      shoutoutType: id ? id : null,
      exemplifyID: id ? id : null
    }));
    this.updateShoutoutArray(userIdArray, text, image, id);
  }

  renderRecognitionModal = () => {
    const { exemplifiesValues } = this.props;
    return (
      <div>
        <StyledModal
          show={this.state.showRecognitionModal}
          onHide={this.showRecognition}
        >
          <StyledBody>
            <MainContent>
              {
                exemplifiesValues ? exemplifiesValues.map((recognition) => (
                  <PeerButton
                    value={recognition.core_value}
                    onClick={() => this.onShoutoutSelect(recognition.core_value, recognition.id, recognition.image)}
                    key={recognition.id}
                  >
                    <img src={`${ImageUrl}/${recognition.image}`} height={recognition.image.includes('passionate.png') ? '30px' : '20px'} />
                    <div>{recognition.core_value}</div>
                  </PeerButton>
                ))
                  : null
              }
            </MainContent>
          </StyledBody>
        </StyledModal>
      </div>
    )
  }

  renderViewCommentModal = () => {
    this.setState((prev) => ({
      showViewCommentModal: !prev.showViewCommentModal
    }))
    this.props.handleScrollTrigger();
  }

  render() {
    const { feedLikeStatus, commentText, clicked, showAddBuddy, showRemoveBuddyButton, showRemoveBuddyPopUp, currentButton } = this.state;
    const { disableButton, feed, getCommentsForPost, userName, userId, commentCount, buddyDetails, history, socialCallBack, description,
      showViewCommentModal, renderViewCommentModal, specialFeed, renderViewCommentAndGetComment, t } = this.props;
    const menuIcon = (
      <i className="fas fa-ellipsis-v" />
    );
    return (
      <div id={feed.id} key={feed.id + "key"}>
        <HeaderContainer>
          <ImageWrapper display={1} borderRadius={'3px'}>
            {this.imgSrc(false)}
          </ImageWrapper>
          <UserInformation isOwnPost={userId == feed.uid} >
            <span onClick={() => this.navigateUserProfile(feed.uid)}>{(feed.fname && feed.lname) ? (feed.fname + ' ' + feed.lname) : userName}</span>
            <div>
              {feed.location}
            </div>
          </UserInformation>
          {showRemoveBuddyButton ? <RemoveButtonContainer onClick={() => this.displayRemovePopUp()}><button>{currentButton}</button></RemoveButtonContainer> : null}
          {((userId === feed.uid) || (feed.friend === 0) || (feed.friend === 2)) ? null :
            <DownAngle width={feed.friend === 1 || feed.friend === 0 ? 1 : 0}>
              <DropdownButton id="buddy" title={menuIcon}>
                <DropdownItem onClick={() => feed.friend === 1 ? this.displayRemovePopUp() : null}>
                  {feed.friend === 1 ? t("Remove Buddy") : null}
                </DropdownItem>
              </DropdownButton>
            </DownAngle>
          }
          {(userId === feed.uid) ? null : feed.friend === 0 ? <AddBuddyContainer>
            <button onClick={() => this.showAddBuddyPopUp(feed.uid)}>
              {t("Add Buddy")}
            </button>
          </AddBuddyContainer> : feed.friend === 2 ? <PendingButton onClick={() => this.showAddBuddyPopUp(feed.uid)}>
            {this.props.t('Pending')}
          </PendingButton> : null}
          {(userId === feed.uid) &&
            <DownAngle width="1">
              <DropdownButton id="editDelete" title={menuIcon}>
                {
                  feed.core_value && (feed.core_value === "Happy Birthday" || feed.core_value === "Work Anniversary") ? null : (
                    <DropdownItem onClick={() => this.props.editPost(feed)}>
                      {t("Edit Post")}
                    </DropdownItem>
                  )
                }
                <DropdownItem onClick={() => this.props.changeEditDeletePopup('delete', feed)}>
                  {t("Delete Post")}
                </DropdownItem>
              </DropdownButton>
            </DownAngle>
          }
        </HeaderContainer>
        <FeedInformation>
          {
            feed.core_value === null ? null : (
              <RecognitionText>
                <span className="first-span">
                  {feed.core_value === "Happy Birthday" || feed.core_value === "Work Anniversary" ? this.props.t("Celebrating") : this.props.t("Giving")}
                  <img src={`https://s3.amazonaws.com/images.trainingamigo.com/${feed.recognition_image}`} />
                  <span className='recognition-name'>{this.props.t(feed.core_value)}</span> {this.props.t("to")}
                </span>
                <span className="second-span">
                  {
                    feed.mentioned_users && feed.mentioned_users.length > 0 && feed.mentioned_users.map((data, index) => (
                      <span key={index}>
                        {`${data.fullname} `}
                      </span>
                    ))
                  }
                </span>
              </RecognitionText>
            )
          }
          {description != null && description !== '' && <Description width="true" contents /* dangerouslySetInnerHTML={{ __html:  description ? convertUnicode(description).split('\\n').join('<br />')  : ''}} / */>{createSafeHTML(description ? convertUnicode(description).split('\\n').join('<br />')  : '')}</Description>}
          <TimeOfPost>
            {moment(convertDateInTimezone(feed.created_at)._d).format('MMM DD, YYYY')}
          </TimeOfPost>
          {feed.image && this.state.hideCommentText ? <WrapImage onClick={() => renderViewCommentAndGetComment(feed, feed.id)}><img src={`${ImageUrl}/${feed.image}`} alt={feed.body || 'placeholder'} /></WrapImage> : null}
          <FeedFeatures id={`like-${feed.id}-parent`} border={"1"}>
            <div>
              <LikeCounts
                active={feed && feed.own_like_post === 1}
                onClick={this.handleLikePopUp}
                disabled={disableButton && clicked}
                id={`like-${feed.id}`}
              >
                {feed.post_like_users && feed.post_like_users.length > 0 ? <img src="/public/images/CommentReplyImages/like2.svg" /> : <img src="/public/images/CommentReplyImages/unlike.svg" />}
                <span id={`like-${feed.id}-span`}>
                  {
                    feed && feed.post_like_users.length > 0 ?
                      feed.post_like_users && feed.post_like_users.length === 1 ? (
                        <Text2 id={`like-${feed.id}-text`}>{feed.post_like_users[0].fullname}</Text2>
                      ) : (
                        feed.post_like_users && feed.post_like_users.length > 1 ? (
                          <Text2 id={`like-${feed.id}-text-2`}>{`${feed.post_like_users[0].fullname} and ${feed.post_like_users.length - 1} others`}</Text2>
                        ) : (
                          <Text2 />
                        )) : null
                  }
                </span>
              </LikeCounts>
              <CommentCounts onClick={() => getCommentsForPost(feed.id)} id={`comment-${feed.id}`}>
                <span>{commentCount} {t("Comments")}</span>
              </CommentCounts>
            </div>
          </FeedFeatures>
          <FeedTabNew background={"none"}>
            <LikeButtonew onClick={this.likeUnlikePost} border={"1"}>
              <div>
                <div>
                  {feedLikeStatus ? <img src="/public/images/CommentReplyImages/like2.svg" /> : <img src="/public/images/CommentReplyImages/unlike.svg" />}
                </div>
                <div>{t('Like')}</div>
              </div>
            </LikeButtonew>
            <LikeButtonew onClick={this.showRecognition} border={"1"}>
              <div>
                <div>
                  <img src="/public/images/social/peer_recognition.svg" />
                </div>
                <div>{t('Recognition')}</div>
              </div>
            </LikeButtonew>
            <LikeButtonew onClick={() => getCommentsForPost(feed.id)}>
              <div>
                <div>
                  <img src="/public/images/social/comment_icon.svg" />
                </div>
                <div>{t('Comment')}</div>
              </div>
            </LikeButtonew>
          </FeedTabNew>
          {this.renderTextArea(commentText)}
        </FeedInformation>
        {this.state.showTextArea && this.renderTextArea(commentText)}
        {this.state.showRecognitionModal && this.renderRecognitionModal()}
        <Suspense fallback={<Waiting/>}>{showViewCommentModal ? <ViewCommentModal onClose={renderViewCommentModal} showModal={showViewCommentModal} feed={specialFeed}
          description={specialFeed.description}
          taggedUsers={specialFeed.shoutout_user_name}
          getCommentsForPost={this.props.getCommentsForPost}
          changeEditDeletePopup={this.props.changeEditDeletePopup}
          editAndDelete={this.props.editAndDelete}
          openEditAndDelete={this.props.openEditAndDelete}
          profileImage={this.props.profileImage}
          updationList="response"
          commentCount={specialFeed.comment_count}
          history={this.props.history}
          enableTrigger={this.props.enableTrigger}
          // key={`feed-${index}-${feed.id}`}
          onChange={this.props.onChange}
          handleFeed={this.props.handleFeed}
          handleModal={this.props.handleModal}
          feedNumber={this.props.feedNumber}
          data={this.props.data}
          exemplifiesValues={this.props.exemplifiesValues}
          showRecognition={this.props.showRecognition}
          value={this.props.value}
          id={this.props.id}
          image={this.props.image}
          feedNo={this.props.feedNo}
          updateFeedsLike={this.props.updateFeedsLike}
          storedFeeds={this.props.storedFeeds}
          editPost={this.props.editPost}
          socialCallBack={socialCallBack}
          handlePhotoPostModal={this.handlePhotoPostModal}
          comments={this.props.comments}
          postReply={this.props.postReply}
          replySuccess={this.props.replySuccess}
        /> : null}
        </Suspense>
        <Suspense fallback={<Waiting/>}>
          {showAddBuddy ? <AddBuddyPopUp showModal={showAddBuddy} onClose={this.showAddBuddyPopUp2} buddyDetails={buddyDetails} showRemoveBuddyPopUp={showRemoveBuddyPopUp} hideCancel={0} socialCallBack={socialCallBack} pendingStatus={feed.friend === 2} /> : null}
        </Suspense>
        <Suspense fallback={<Waiting/>}>
          {showRemoveBuddyPopUp ? <RemoveBuddyPopUp showModal={showRemoveBuddyPopUp} onClose={this.displayRemovePopUp} buddyDetails={feed} history={history} showRemoveBuddyPopUp={showRemoveBuddyPopUp} hideCancel={1} socialCallBack={socialCallBack} /> : null}
        </Suspense>
      </div>
    )
  }
}

SingleFeed.propTypes = {
  feed: PropTypes.object.isRequired,
  disableLikeButton: PropTypes.func.isRequired,
  disableButton: PropTypes.bool.isRequired,
  postComment: PropTypes.func.isRequired,
  likeOrUnlikePost: PropTypes.func.isRequired,
  successLikeOrUnlike: PropTypes.string,
  getCommentsForPost: PropTypes.func.isRequired,
  getCommentsForPostModal: PropTypes.func.isRequired,
  profileImage: PropTypes.string,
  fetchUserName: PropTypes.func.isRequired,
  userName: PropTypes.string,
  updationList: PropTypes.string,
  changeEditDeletePopup: PropTypes.func.isRequired,
  openEditAndDelete: PropTypes.func.isRequired,
  editAndDelete: PropTypes.bool.isRequired,
  userId: PropTypes.number,
  fetchUserId: PropTypes.func,
  history: PropTypes.object,
  description: PropTypes.string,
  commentCount: PropTypes.number,
  fetchUserPosts: PropTypes.func.isRequired,
  taggedUsers: PropTypes.string,
  userCompany: PropTypes.object.isRequired,
  enableTrigger: PropTypes.bool,
  buddyDetails: PropTypes.array,
  sendFriendRequest: PropTypes.func,
  onChange: PropTypes.func,
  handleModal: PropTypes.func.isRequired,
  handleFeed: PropTypes.func,
  unFriendUser: PropTypes.func,
  getPostLikeDetails: PropTypes.func,
  feedNumber: PropTypes.number,
  postLikeDetails: PropTypes.array,
  data: PropTypes.array.isRequired,
  addBuddy: PropTypes.func,
  exemplifiesValues: PropTypes.array,
  showRecognition: PropTypes.func,
  feedNo: PropTypes.number,
  value: PropTypes.string,
  id: PropTypes.number,
  image: PropTypes.string,
  updateFeedsLike: PropTypes.func,
  editPost: PropTypes.func,
  storedFeeds: PropTypes.array,
  socialCallBack: PropTypes.func,
  handlePhotoPostModal: PropTypes.func,
  comments: PropTypes.array,
  postReply: PropTypes.func,
  replySuccess: PropTypes.string,
  hideCommentText: PropTypes.bool,
  showViewCommentModal: PropTypes.bool,
  renderViewCommentModal: PropTypes.func,
  specialFeed: PropTypes.object,
  renderViewCommentAndGetComment: PropTypes.func,
  handleScrollTrigger: PropTypes.func,
  t: PropTypes.func,
  managePostLikedUsers: PropTypes.func,
};

const mapStateToProps = (state) => ({
  success: state.social.success,
  userId: state.profileData.userId,
  userCompany: state.profileData.userCompany,
  postLikeDetails: state.social.postLikeDetails,
  buddyDetails: state.social.buddyDetails,
  userPosts: state.social.userPosts,
});

const mapDispatchToProps = (dispatch) => ({
  postComment: (commentData, updationList) => dispatch(addCommentOnPost(commentData, updationList)),
  disableLikeButton: () => dispatch(disableButton()),
  likeOrUnlikePost: (data, updationList) => dispatch(likeUnlikePost(data, updationList)),
  fetchUserName: () => dispatch(getUserName()),
  fetchUserId: () => dispatch(getUserId()),
  fetchUserPosts: (id, history) => dispatch(getUserPosts(id, history)),
  sendFriendRequest: (buddyId, bool) => dispatch(sendFriendRequest(buddyId, bool)),
  unFriendUser: (data, bool) => dispatch(unFriendUser(data, bool)),
  getPostLikeDetails: (data) => dispatch(getPostLikeDetails(data)),
  addBuddy: (data) => dispatch(addBuddy(data)),
  managePostLikedUsers: (res) => dispatch(managePostLikedUsers(res)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SingleFeed));